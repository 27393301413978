<template>
  <div class="detail-page">
    <div class="top-bg" :style="{ background: backgroundColor }" v-show="unPhotoFlag"></div>
    <div class="detail-info" v-show="unPhotoFlag">
      <div class="arc-background"></div>
      <div class="card-box flex-column-center" :style="{ background: backgroundColorLigth }">
        <div class="title">{{title}}</div>
        <div class="card-img">
          <img :src="logoUrl" alt="" />
        </div>
        <div class="card-text">
          <div>
            背景色：{{colorBg}}
          </div>
          <div>
            分辨率：{{ mobFbl }} dpi
          </div>
          <div>
            像素/冲印尺寸：{{ mobXscc }} / {{ mobSccc }}mm
          </div>
        </div>
      </div>
      <div class="content-box">
        <div class="content-title">
          拍摄要求：
        </div>
        <div class="des-box" v-html="newRichText"></div>
      </div>
      <div class="bottom-box">
        <div class="album-camera-box flex-between">
            <div class="step-box flex-center" @click="onOpenAlbum">
              <span :style="{ color: backgroundColor }">从相册选择</span>
              <div class="common dh-icon">
                <img src="@/assets/images/xcxz-icon.png" alt="" />
              </div>
            </div>
            <div class="step-box flex-center" ref="inputRef" @click="startPhoto">
              <span :style="{ color: backgroundColor }">用相机拍照</span>
              <div class="common psm-icon">
                <img src="@/assets/images/xjpz-icon.png" alt="" />
              </div>
            </div>
        </div>
      </div>
    </div>
    <div class="photo" v-show="photoFlag">
      <div class="photo-top">
        <video ref="video" x5-playsinline  playsinline  webkit-playsinline="true" ></video>
        <canvas ref="canvas" style="display: none;"></canvas>
        <div class="overlay"></div>
      </div>
      <div class="photo-bottom">
        <button class="photo-back" @click="stopPhoto"></button>
        <button class="photo-confirm" @click="takePhoto"></button>
      </div>
    </div>
    <!-- 错误信息提示 -->
    <div class="van-overlay" style="z-index: 2005;" v-show="isVisible"></div>
    <div v-if="isVisible" role="dialog" class="van-dialog" aria-labelledby="错误">
        <div class="header">{{errorMessage}}</div>
        <div class="footer" @click="reloadWeb">
            <button class="van-button van-button--default van-button--large van-dialog__confirm">
                <span class="confirm-span">确认</span>
            </button>
        </div>
    </div>
  </div>
</template>
<script>
import { getPhotoSpec } from "@/api/app-base.js";
export default {
  components: {
  },
  data() {
    return {
      fileList: [],
      previewImages: [],
      previewVisible: false,
      navRef: null,
      imageUrl: null,
      title: '',
      colorBg: '',
      mobFbl: '',
      mobXscc: '',
      richText: '',
      newRichText: '',
      logoUrl: '',
      mobSccc: '',
      logoImageUrl: '',
      backgroundColor: '#ffffff',
      backgroundColorLigth: '#ffffff',
      unPhotoFlag: true,
      photoFlag: false,
      stream: null,
      cameraOpenFlag: false,
      isVisible: false
    }
  },
  created() {
    this.init();
    this.setStyle();
    this.setupCamera();
    this.getShootingRequire();
  },
  mounted() {
    this.setupCamera();
  },
  methods:{
    init() {
      this.cameraOpenFlag=false
    },
    setStyle() {
      this.backgroundColor = this.$localStorage.get('styleColor')
      this.backgroundColorLigth =  this.$localStorage.get('styleColorLight')
    },
    getShootingRequire(){
      const id = this.$store.state.photoSpecCode;
      let specParam = {
        photoCode : this.$store.state.photoSpecCode,
        sid: this.$store.state.sid
      }
      getPhotoSpec(specParam).then((res)=>{
        this.title = this.$store.state.descName;
        this.colorBg = res.data.mobBjs
        this.mobFbl = res.data.mobFbl
        this.mobXscc = res.data.mobXscc
        this.mobSccc = res.data.mobSccc
        const tempUrl = this.$store.state.descUrl;
        if(tempUrl){
          this.logoUrl = tempUrl
        }else{
          this.logoUrl = res.data.logoUrl
        }
        const tempContent = this.$store.state.descContent;
        if(tempContent){
          this.richText = tempContent
          let reg = new RegExp('<br/>','g')
          this.newRichText = this.richText.replace(reg,'\n')
        }else{
          this.richText = res.data.richText
          let reg = new RegExp('<br/>','g')
          this.newRichText = this.richText.replace(reg,'\n')
        }
      })
    },
    onTakePhoto(cameraFlag) {
      const input = document.createElement('input');
      input.type = 'file';
      input.accept = 'image/*';
      if(cameraFlag){
        input.capture = 'camera';
      }

      /** 
      input.style.display = 'none';
      document.getElementById('customButton').addEventListener('click', function() {
        input.click();
      });
      */

      input.addEventListener('change', (event) => {
        const file = event.target.files[0];
        if(file){
          if(file && file instanceof File) {
            this.$store.commit('setUploadFileName',file.name)
          }
          this.handleImage(file);
        }
      });
      
      input.click();
    },
    handleImage(file) {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        const imgSrc = e.target.result;
        this.$store.commit('setUpload', imgSrc)
        this.$router.push("/examplereference");
      };
      reader.readAsDataURL(file);
    },
    onOpenAlbum(){
      this.onTakePhoto(false);
    },
    onOpenPhoto(){
      this.onTakePhoto(true);
    },
    async setupCamera() {
      const constraints = {
        video: {
          facingMode: { exact: "environment" }
        }
        //video: true
      };
      try {
        this.stream = await navigator.mediaDevices.getUserMedia(constraints);
        this.$refs.video.srcObject = this.stream;
        this.cameraOpenFlag=true
      } catch (error) {
        this.cameraOpenFlag=false
        console.error('Error accessing the camera: ', error);
      }
    },
    takePhoto() {
      const canvas = this.$refs.canvas
      const video = this.$refs.video
      const context = canvas.getContext('2d')
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      context.drawImage(video, 0, 0, canvas.width, canvas.height)
      this.photo = canvas.toDataURL('image/jpeg')
      this.closePhoto()
      this.stopMediaStream()
      this.$store.commit('setUpload', this.photo)
      this.$router.push("/examplereference");
    },
    stopMediaStream() {
      if (this.stream) {
        this.stream.getTracks().forEach(track => {
          track.stop();
        });
        this.stream = null;
      }
    },
    startPhoto(){
      if(this.cameraOpenFlag){
        this.stream = null;
        const video = this.$refs.video
        video.play()
        this.unPhotoFlag=false
        this.photoFlag=true
      }else{
        this.messageBox("摄像头初始化失败")
      }
    },
    stopPhoto() {
      this.closePhoto()
      this.unPhotoFlag=true
      this.photoFlag=false
    },
    closePhoto() {
      this.stream = null;
      const video = this.$refs.video
      video.pause()
    },
    reloadWeb(){
      this.isVisible = false
      if(!this.cameraOpenFlag){
        this.setupCamera()
      }
    },
    messageBox(msg){
      this.isVisible = true
      this.errorMessage = msg
    }
  }
}
</script>
<style lang="less" scoped>
  .top-bg {
    height: 10rem;
    width: 100vw;
    border-radius: 0 0 2rem 2rem;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
  .detail-page {
    width: 100vw;
    height: 100vh;
    .detail-info {
      height: 100%;
    }
    .detail-title {
      font-size: 30px;
      margin: 0 auto;
    }
    .arc-background {
      width: 100%;
      height: 420px;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
    .card-box {
      width: 680px;
      height: 421px;
      background-color: #86b3e0;
      border-radius: 20px;
      margin: 0 auto 0;
      margin-top: -24vh;
      z-index: 10;
      .title {
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #000000;
        line-height: 30px;
      }
      .card-img {
        width: 160px;
        height: 116px;
        margin: 26px 0 34px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .card-text {
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #000000;
        line-height: 40px;
      }
    }
    .content-box {
      height: 36%;
      padding: 36px 60px 0;
      .content-title {
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: 600;
        color: #000000;
        line-height: 30px;
      }
      .des-box {
        margin-top: 28px;
      }
    }
    .bottom-box {
      position: sticky;
      top: 92%;
      padding: 0 60px 0;
      .album-camera-box {
        width: 100%;
        margin-top: 56px;
        .step-box {
          width: 220px;
          height: 90px;
          background-color: #ebebf3;
          box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.35);
          border-radius: 24px;
          position: relative;
          .selection-img {
            width: 158px;
            height: 33px;
            text-align: center;
          }
          span {
            font-size: 30px;
            font-weight: bold;
            color: #f0f0f0;
          }
        }
        .common {
          width: 40px;
          height: 40px;
          position: absolute;
          top: -20px;
          left: -16px;
        }
        .psm-icon {
          top: -26px;
        }
      }
    }

    .photo {
      width: 100%;
      height: 100%;
      .photo-top {
        width: 100%;
        height: 85%;
        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 90%;
          background-image: url("~@/assets/images/model.jpg");
          background-color: rgba(0, 0, 0, 0);
          background-size: contain;
          background-position: top;
          background-repeat: no-repeat;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          z-index: 999;
        }
      }
      .photo-bottom {
        width: 100%;
        height: 15%;
        background-color:#696969;
        display: inline-flex;
        .photo-back {
          border: none;
          margin: 3vh 0 0 16vw;
          background-color: #696969;
          width: 120px;
          height: 120px;
          background-image: url("~@/assets/images/back.jpg");
          background-size: contain;
          background-repeat: no-repeat;
        }
        .photo-confirm {
          border: none;
          margin: 3vh 0 0 32vw;
          background-color: #696969;
          width: 120px;
          height: 120px;
          background-image: url("~@/assets/images/take.jpg");
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }
  }
  .van-dialog {
    position: fixed;
    top: 45%;
    left: 50%;
    width: 15rem;
    height: 14vh;
    overflow: hidden;
    font-size: 0.8rem;
    transf: translate3d(-50%,-50%,0);
    backface-visibility: hidden;
    transition: .3s;
    z-index: 9999;
    .header {
      height: 6vh;
      font-weight: 500;
      line-height: 8vh;
      text-align: center;
      vertical-align: bottom;
    }
    .footer {
      height: 6vh;
      padding-top: 0.5rem;
    }
    .van-button {
      border-top: 0.2px solid #d0d0d0;
    }
    .confirm-span {
      color: #1989fa;
    }
  }
</style>
<template>
  <div>
    <div class="popup-box">
      <van-popup v-model="showDetail" position="bottom" :overlay="false" closeable
                 close-icon-position="top-left"
                 close-icon="close" :style="{ height: '45%' }" @click-close-icon="handleCloseicon">
        <div class="detail-box">
          <div class="detail-message-box">
            <div class="top">
              自助机编号：<span>{{equipCode}}</span>
            </div>
            <div class="center">
              详细地址：<span>{{ detailAddress.areaInfo.address }}</span>
            </div>
            <div class="bottom">
              工作时间：{{beginTime}}-{{endTime}}
            </div>
          </div>
          <div class="dash-line"></div>
          <div class="more-imgs display-flex">
            <div class="left-icon">

            </div>
            <div class="right-more-images display-flex">
              <div class="more-text">更多实景图片：</div>
              <div>
                <img src="@/assets/images/img1.png" alt="" />
                <img src="@/assets/images/img1.png" alt="" />
                <img src="@/assets/images/img1.png" alt="" />
              </div>
            </div>
          </div>
          <!--  导航和设备-->
          <div class="album-camera-box flex-between">
            <div class="step-box step2-img flex-center" @click="opengaode">
              <!--<img class="selection-img" src="@/assets/images/ksdh.png" alt="">-->
              <span class="step-msg" :style="{ color: backgroundColor }">开始导航</span>
              <div class="common dh-icon">
                <img src="@/assets/images/dh-icon.png" alt="" />
              </div>
            </div>
            <div class="step-box step2-img  flex-center" @click="handleFindDevice">
              <!--<img  class="selection-img" src="@/assets/images/hqpsm.png" alt="">-->
              <span class="step-msg" :style="{ color: backgroundColor }">获取拍摄码</span>
              <div class="common psm-icon">
                <img src="@/assets/images/psm-icon.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>
<script>
import AlbumCamera from './AlbumCamera.vue'
export default {
  name: 'ChildTest',
  components: {
    AlbumCamera
  },
  data(){
    return {
      addressList: [],
      beginTime: '',
      endTime: '',
      backgroundColor: null,
    }
  },
  props: {
    showPopup: {
      type: Boolean,
      default: false
    },
    detailAddress: {
      type: Object,
      default: () => ({})
    },
    equipCode: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.initStyle()
  },
  computed: {
    showDetail: {
      get() {
        return this.showPopup
      },
      set(val) {
        this.$emit('update:showPopup', val)
      }
    }
  },
  methods: {
    initStyle() {
      this.backgroundColor=this.$localStorage.get('styleColor')
    },
    handleFindDevice() {
      this.$emit('onSubTrigger');
    },
    handleCloseicon(){
      this.$emit('onCloseIcon');
    },
    opengaode(){
      //const url = `iosamap://viewMap?sourceApplication=实名证照&poiname=我的地址&lat=31.241718&lon=121.438821&dev=0`;
      const url = `iosamap://path?sourceApplication=实名证照&&slat=31.241718&slon=121.438821&sname=起点名称&dlat=31.281718&dlon=121.438821&dname=终点名称&dev=0&t=0`;
      window.location.href = url;
    }
  }
}
</script>
<style scoped lang="less">
.detail-box {
  margin: 0 50px 50px;
  .detail-message-box {
    padding: 50px 6px 2px 6px;
    font-family: Source Han Sans CN;
    font-weight: 800;
    font-size: 28px;
    color: #000000;
    line-height: 58px;

  }
  .top {

  }
  .center {

  }
  .bottom {

  }
  .dash-line {
    border: 1px dashed #ababab;
    margin-bottom: 28px;
  }
  .more-imgs {

  }
  .left-icon {
    width: 35px;
    height: 35px;
    background-image: url("~@/assets/images/more-icon.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
  .right-more-images {
    .more-text {
      font-family: Source Han Sans CN;
      font-weight: 500;
      font-size: 18px;
      color: #000000;
      margin-left: 30px;
    }
    img {
      width: 128px;
      height: 70px;
      margin-left: 12px;
    }
  }
  .album-camera-box {
    width: 100%;
    margin-top: 56px;
    .step-box {
      width: 200px;
      height: 90px;
      background-color: #ebebf3;
      box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.35);
      border-radius: 24px;
      position: relative;
      .selection-img {
        width: 158px;
        height: 33px;
        text-align: center;
      }
      .step-msg {
        font-size: 28px;
        font-weight: bold;
      }
    }
    .common {
      width: 40px;
      height: 40px;
      position: absolute;
      top: -20px;
      left: -16px;
    }
    .dh-icon {
      //top: 5px;
    }
    .psm-icon {
      top: -26px;
    }
  }
}
</style>
<style lang="less">
.popup-box {
 .van-icon-close  {
   &::before {
     font-size: 40px !important;
   }
  }
  .custom-popup {
    border-radius: 60px 60px 0 0;
  }
}

</style>

<template>
  <div class="container">
<!--    <common-header title="拍摄网点" :showShare="true" />-->
    <div class="shooting-map">
      <MapContainer :marks="marks"/>
      <div class="branches-box" v-show="isShowList">
        <div class="device-details-box flex-between" v-for="(item, index) in addressList" @click="handleDetail(item)">
          <div class="device-box flex-between">
            <div class="img-box">
              <img src="@/assets/images/zzj.png" alt=""  />
            </div>
            <div class="device-box">
              <div class="name" :style="{ color: backgroundColor }">自助机</div>
              <div class="address-box">{{ item.areaInfo.address }}</div>
            </div>
          </div>
          <div class="detail-box flex">
            <div class="detail-title">
              详情
            </div>
            <div class="detail-img">
              <svg t="1708670930985" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                   p-id="4200" width="48" height="48">
                <path
                    d="M816.653093 469.544049c-1.49505-1.49505-3.060709-2.874467-4.676509-4.15974L428.47519 79.895655c-21.351284-21.350261-55.967686-21.350261-77.317946 0-21.350261 21.351284-21.350261 55.966663 0 77.317946l349.259522 351.247806L343.656409 864.883049c-21.350261 21.351284-21.350261 55.966663 0 77.31897 21.350261 21.350261 55.966663 21.350261 77.317946 0l390.998135-390.661468c1.616824-1.286296 3.183506-2.664689 4.679579-4.160763 10.740622-10.740622 16.07307-24.839735 16.006556-38.917358C832.726164 494.38276 827.393715 480.284671 816.653093 469.544049z"
                    fill="#326EDE" p-id="4201"></path>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <device-detail :showPopup.sync = "isShowDetail" @onSubTrigger="handleDevice" @onCloseIcon="handleCloseIcon" :detailAddress="detailAddress" :equipCode="equipCode" />
  </div>
</template>
<script>
import DeviceDetail from "@/views/modules/DeviceDetail.vue";
import MapContainer from "@/components/MapContainer.vue";
import { getNearbyselfmachines } from "@/api/app-base"
export default {
  name: "ShootingBranches",
  data() {
    return {
      isShowDetail: false,
      isShowList:　true,
      detailAddress: {
        areaInfo: {
          areaInfo: 'sad'
        }
      },
      title: '',
      equipCode: '',
      addressList: [],
      marks: null,
      backgroundColor: null,
    }
  },
  components: {
    MapContainer,
    DeviceDetail,
  },
  props: {
    setHeaderLeft: Function,
  },
  mounted () {
    this.setStyle();
    this.getMachinelist();
    this.setHeaderLeft(() => {
      this.$router.push({
        path: '/'
      })
    })
  },
  methods: {
    setStyle() {
      this.backgroundColor=this.$localStorage.get('styleColor')
    },
    handleDetail(item) {
      this.isShowDetail = true;
      this.detailAddress = item;
      this.equipCode = item.equipCode;
    },
    handleCloseIcon(){
      this.isShowList = true;
    },
    handleDevice(){
      this.$router.push('/machineshooting');
    },
    getMachinelist() {
      let data = {
        lng: this.$store.state.lng,
        lat: this.$store.state.lat,
        sid: this.$store.state.sid
      }
      getNearbyselfmachines(data).then(res => {
        this.addressList = res.data.slice(0, 1)
        this.marks = res.data
      })
    }
  }
}
</script>
<style scoped lang="less">
.container {
  width: 100%;
  height: 100%;
  .shooting-map {
    width: 100%;
    height: 100vh;
    position: relative;
    .branches-box {
      width: 90%;
      background-color: #fff;
      padding: 12px 46px;
      overflow-y: auto;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .device-details-box {
    padding: 18px 0 18px;
    .device-box {
      .img-box {
        width: 70px;
        height: 80px;
        margin-right: 18px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .name {
        font-family: Source Han Sans CN;
        font-weight: 800;
        font-size: 28px;
        white-space: normal;
      }

      .address-box {
        font-family: Source Han Sans CN;
        font-size: 26px;
        color: #000000;
      }
    }

    .detail-box {
      .detail-title {
        width: 68px;
        height: 30px;
        font-family: Source Han Sans CN;
        font-weight: 800;
        font-size: 30px;
        color: #000000;
      }

      .detail-img {
        width: 30px;
        height: 30px;
        svg {
          width: 30px;
        }
      }
    }
  }
}
</style>
